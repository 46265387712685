import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100vh;
  padding: 4rem 2rem;
  color: #fff;
  background: #1a1a1a;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Content = styled.div`
  max-width: 600px;
  text-align: left;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Montserrat', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #ea864d;
  text-align: center;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  color: #e2e2e2;
  margin-bottom: 1rem;

  a {
    color: #ea864d;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: #d97842;
    }
  }
`;

const Contacts = () => (
  <Container>
    <Content>
      <Title>Контакты</Title>
      <Paragraph>
      ИП Лихторович Андрей Юрьевич
        <br />ИНН: 505301795240
        <br />ОГРНИП: 325508100081928
      </Paragraph>
      <Paragraph>
        Email: <a href="mailto:support@premier-game.ru">support@premier-game.ru</a>
        <br />Для сотрудничества: <a href="mailto:support@premier-game.ru">support@premier-game.ru</a>
      </Paragraph>
    </Content>
  </Container>
);

export default Contacts;
